<template>
  <div :class="{ 'has-logo': showLogo }">
    <logo v-if="showLogo" :collapse="isCollapse" />
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :unique-opened="false"
        :collapse-transition="false"
        :background-color="variables.menuLinearGradient"
        :text-color="variables.menuText"
        :active-text-color="variables.menuActiveText"
        mode="vertical"
      >
        <sidebar-item v-for="route in routes" :key="route.path" :item="route" :base-path="route.path" />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from './Logo'
import SidebarItem from './SidebarItem'
import variables from '@/styles/variables.module.scss'

export default {
  components: { SidebarItem, Logo },
  computed: {
    ...mapGetters(['routes', 'sidebar']),
    data() {
      return {
        username: localStorage.getItem('username'),
      }
    },
    // routes() {
    //   // 获取的当前路由的路由表 permission => addRouters 方法后 这个地方不会响应式的变化
    //   var data = this.$router.options.routes
    //   console.log('dsdsds',data)
    //   data.map((item) =>{
    //      if(item.path=='/admin'&&localStorage.getItem('username')!=='admin'){
    //        item.hidden=true
    //      }
    //     }
    //   )
    //   return data
    // },
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo
    },
    variables() {
      return variables
    },
    isCollapse() {
      return !this.sidebar.opened
    },
  },
}
</script>
