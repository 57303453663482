<template>
  <div class="navbar">
    <hamburger
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
    />

    <breadcrumb class="breadcrumb-container" />

    <div class="right-menu">
      <el-dropdown class="avatar-container" trigger="click">
        <div class="avatar-wrapper" @click="toMessage(userinfoId)">
          <div class="circle blue" v-if="unEditCount != null">
            {{ unEditCount }}
          </div>
          <el-badge :value="unReadCount ? unReadCount : 0" class="item" type="danger">
            <div class="user-avatar">{{ userinfo }}</div>
          </el-badge>
          <el-icon><el-icon-caret-bottom /></el-icon>
        </div>
        <template v-slot:dropdown>
          <el-dropdown-menu class="user-dropdown">
            <el-dropdown-item v-if="unReadCount == null">
              <span style="display: block" @click="toMessaged(userinfoId)">{{
                $t('notice.notify')
              }}</span>
            </el-dropdown-item>
            <router-link to="/">
              <el-dropdown-item >
                {{ $t('general.profile') }}
              </el-dropdown-item>
            </router-link>
            <el-dropdown-item divided>
              <span style="display: block" @click="passwd">{{
                $t('general.passwd')
              }}</span>
            </el-dropdown-item>
            <el-dropdown-item divided>
              <span style="display: block" @click="logout">{{
                $t('general.logout')
              }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>

    <div class="right-menu">
      <el-dropdown class="avatar-container" trigger="click">
        <div class="avatar-wrapper">
          <div class="user-avatar theme-menu">{{ theme }}</div>
          <el-icon><el-icon-caret-bottom /></el-icon>
        </div>
        <template v-slot:dropdown>
          <el-dropdown-menu class="user-dropdown">
            <el-dropdown-item v-for="(value, item) in themes"
              :label="item"
              :value="item"
              :key="item">
              <span class="theme-menu" @click="changeTheme(value, item)">
                <el-icon v-if="item==this.$store.getters.theme"><Check /> </el-icon>
                <el-icon v-else> </el-icon>
                <span class="theme-icon" :style="getThemeBackground(value)" ></span>
                {{item}}
              </span>
            </el-dropdown-item>
            
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>

    <div class="right-menu">
      <el-dropdown class="avatar-container" trigger="click">
        <div class="avatar-wrapper">
          <div class="user-avatar">{{ language }}</div>
          <el-icon><el-icon-caret-bottom /></el-icon>
        </div>
        <template v-slot:dropdown>
          <el-dropdown-menu class="user-dropdown">
            <el-dropdown-item>
              <span @click="changeType('zh')"><svg-icon class="language-icon" icon-class="chinese"/> 中文</span>
            </el-dropdown-item>
            <el-dropdown-item divided>
              <span @click="changeType('en')"
                ><svg-icon class="language-icon" icon-class="english"/> English</span
              >
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script >
import { CaretBottom as ElIconCaretBottom } from '@element-plus/icons'
/* eslint-disable*/
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'
import { removeToken } from '@/utils/auth'
import { unreadList } from '@/api/notify'
import ThemeEngine from '@/utils/theme'
export default {
  components: {
    Breadcrumb,
    Hamburger,
    ElIconCaretBottom,
  },
  data() {
    return {
      userinfo: '',
      language: 'language',
      theme:'dark',
      themes: ThemeEngine.Themes,
      // unReadCount:''
      timer: '',
      storeActions: [
        'app/getCount',
        'app/geteditCount'
      ]
    }
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'avatar',
      'unReadCount',
      'unEditCount', //硬件变更运营未编辑数量
    ]),
  },
  created() {
    console.log("current theme is", this.$store.getters.theme);
    console.log("current language is", this.$store.getters.language);
    this.$store.dispatch('app/getCount')
    this.$store.dispatch('app/geteditCount') //硬件变更运营未编辑数量
    this.timer = setInterval(() => {
      this.storeActions.forEach((action) => {
        this.$store.dispatch(action)
      })
    }
      ,1000 * 60 * 5
    )
  },
  destroyed(){
    clearInterval(this.timer)
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    getThemeBackground(theme){
      return { background: theme['dark-blue']};
    },
    async logout() {
      await this.$store.dispatch('user/logout')
      removeToken()
      localStorage.removeItem('username')
      localStorage.removeItem('userid')
      localStorage.removeItem('local')
      localStorage.removeItem('action')
      // this.$router.push(`/login?redirect=${this.$route.fullPath}`)
      window.location.href = process.env.NODE_ENV === 'development' ? `/login` : '/login_erp/'
    },
    changeTheme(themeData, theme){
      this.theme = theme
      ThemeEngine.setThemeColor(themeData)
      localStorage.setItem('theme', theme)
      this.$store.dispatch('app/setTheme', theme)
      localStorage.setItem('action', 'changeType')
    },
    changeType(type) {
      localStorage.setItem('local', type)
      this.$i18n.locale = type
      console.log("Set Language to ", type);
      this.$store.dispatch('app/setLanguage', type)
      // locale.value = type
      // if (type === 'zh') this.language = '语言'
      // else if (type === 'en') this.language = 'LANGUAGE'
      // location.reload()
      localStorage.setItem('action', 'changeType')
    },
    passwd() {
      this.$router.push({name:`userpassword`})
    },
    toMessage(data) {
      //console.log(this.unReadCount);
      if (this.unReadCount != null) {
        this.$router.push({
          name: 'notice_messages',
          query: {
            userId: data,
          },
        })
      }
    },
    toMessaged(data) {
      this.$router.push({
        name: 'notice_messages',
        query: {
          userId: data,
        },
      })
    },
    // async getUnReadCount(){
    //   let res = await unreadList()
    //   this.unReadCount = res.data.count
    // }
  },
  mounted() {
    if (!localStorage.getItem('action')) {
      this.changeType(localStorage.getItem('lang'))
    }
    this.userinfo = localStorage.getItem('username')
    this.userinfoId = localStorage.getItem('userid')
    // var type = localStorage.getItem('local')
    // if (type === 'zh') this.language = '语言'
    // else if (type === 'en') this.language = 'LANGUAGE'
    // this.getUnReadCount()
  },
}
</script>

<style lang="scss" scoped>

.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  color: var(--pyxis-menu-text-color) !important;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  background: linear-gradient(
    90deg,
    var(--pyxis-dark-blue),
    var(--pyxis-light-blue),
    var(--pyxis-dark-blue),
  );
  background-size: 400% 400%;
	animation: gradient 5s ease infinite;
  animation: pyxis 5s ease infinite;
  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: margin-right 0.3s;
    -webkit-tap-highlight-color: transparent;
    color: var(--pyxis-menu-text-color);
    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    color: var(--pyxis-menu-text-color);
    float: left;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;
    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      // color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 2rem;
      color: var(--pyxis-menu-text-color) !important;

      .avatar-wrapper {
        display: flex;
        margin-top: 1rem;
        // position: relative;

        .user-avatar {
          cursor: pointer;
          margin-right: 10px;
          border-radius: 10px;
          text-transform: capitalize;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 20px;
          font-size: 12px;
        }
      }
    }
  }
}
div:deep(.is-fixed) {
  top: -5px;
}
.circle {
  height: 20px;
  width: 20px;
  border-radius: 25px;
  background-color: var(--pyxis-dark-blue);
  color: var(--pyxis-menu-text-color);
}
.blue {
  background-color: var(--pyxis-light-blue);
  position: absolute;
  left: -10px;
  text-align: center;
  font-size: 12px;
  height: 18px;
  line-height: 18px;
  white-space: nowrap;
  border: 1px solid var(--pyxis-menu-text-color);
  top: 2px;
}
.theme-menu{
  text-transform: capitalize;
}
.theme-icon{
  width: .8rem;
  height: .8rem;
  padding-top: .1rem;
  margin-right: .5rem;
  margin-left: .5rem;
  display: inline-block;
}
.language-icon{
  display: inline-block;
  margin-right: .5rem;
  &.svg-icon{
    width: 1rem;
    height: 1rem;
  }
}

</style>
