import * as Vue from 'vue'
import Layout from '@/layout'
// import Vue from '@/main'

export default {
  path: '/admin',
  component: Layout,
  // redirect: '/admin/user',
  name: 'administrator',
  meta: { title: 'menus.admin', icon: 'admin' },
  children: [
    //   {
    //     path: 'department',
    //     name: 'department',
    //     component: () => import('@/views/contacts/department/index.vue'),
    //     meta: { title: 'Department', icon: 'tree' },
    //     // redirect: '/contacts/department',
    //   },
    {
      path: 'users',
      name: 'admin_user',
      component: Vue.defineAsyncComponent(
        () => import('@/views/admin/users.vue')
      ),
      meta: { title: 'menus.users', icon: 'yonghu' },
    },
    {
      path: 'user/:id(\\d+)',
      name: 'admin_user_info',
      hidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/contacts/user/detail.vue')
      ),
      meta: { title: 'menus.userInfo', noCache: true, activeMenu: '/admin/users' },
    },
    {
      path: 'department',
      name: 'admin_department',
      component: Vue.defineAsyncComponent(
        () => import('@/views/contacts/department/index.vue')
      ),
      meta: { title: 'menus.department', icon: 'tree' },
      // redirect: '/contacts/department',
    },
    {
      path: 'depinfo/:id(\\d+)',
      name: 'admin_department_info',
      hidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/contacts/department/depdetail.vue')
      ),
      meta: {
        title: 'Information',
        noCache: true,
        activeMenu: '/admin/department',
      },
    },
    {
      path: 'roles',
      name: 'adminRole',
      // hidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/admin/role.vue')
      ),
      meta: { title: 'menus.role', icon: 'user-role' },
    },
    {
      path: 'role/:id(\\d+)',
      name: 'RoleDetail',
      hidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/admin/roleDetail.vue')
      ),
      meta: { title: 'menus.role', icon: 'user-role' },
    },
    {
      path: 'permission',
      name: 'admin_Permission',
      // hidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/admin/permission.vue')
      ),
      meta: { title: 'menus.perm', icon: 'permission' },
    },
    {
      path: 'notifications',
      name: 'Email_Config',
      component: Vue.defineAsyncComponent(
        () => import('@/views/admin/email.vue')
      ),
      meta: { title: 'menus.notification', icon: 'mail-notification' },
    },
    {
      path: 'approvalFlow',
      name: 'approval_Flow',
      component: Vue.defineAsyncComponent(
        () => import('@/views/admin/approvalFlow.vue')
      ),
      meta: { title: 'menus.approvalFlow', icon: 'approval' },
    },
    {
      path: 'approvalInfo/:id(\\d+)',
      name: 'approvalInfo',
      hidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/admin/approvalFlowdetail.vue')
      ),
      meta: {
        title: 'Model',
        noCache: true,
        activeMenu: '/admin/approvalFlow',
      },
    },
    {
      path: 'znnotice',
      name: 'znnotice',
      component: Vue.defineAsyncComponent(
        () => import('@/views/notice/index.vue')
      ),
      meta: { title: 'notice.insite', icon: 'messages' },
    },
    {
      path: 'znnoticeinfo',
      name: 'znnoticeinfo',
      hidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/notice/info.vue')
      ),
      meta: { title: 'notice.insiteDet', noCache: true , activeMenu: '/admin/znnotice',},
    },
  ],
}
