import * as Vue from 'vue'
import Layout from '@/layout'
export default {
  path: '/price',
  name: 'price',
  component: Layout,
  // meta: { title: 'menus.product', icon: 'chanpinku' },
  children: [
    {
      path: 'priceManagement',
      name: 'priceManagement',
      component: Vue.defineAsyncComponent(
        () => import('@/views/price/product_price.vue')
      ),
      meta: { title: 'menus.price_management', icon: 'price', affix: true },
    },
  ],
}
