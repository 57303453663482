import Cookies from 'js-cookie'
import { unreadList, uneditList } from '@/api/notify'
const state = {
  count: null,
  uneditcount: null,
  sidebar: {
    opened: Cookies.get('sidebarStatus')
      ? !!+Cookies.get('sidebarStatus')
      : true,
    withoutAnimation: false,
  },
  device: 'desktop',
  language: Cookies.get('language') || 'zh',
  theme: Cookies.get('theme') || 'dark blue',
}

const mutations = {
  SET_MESSAGECOUNT: (state, count) => {
    if (count == 0) {
      state.count = null
    } else {
      state.count = count
    }
  },
  SET_EDITCOUNT: (state, count) => {
    if (count == 0) {
      state.uneditcount = null
    } else {
      state.uneditcount = count
    }
  },
  TOGGLE_SIDEBAR: (state) => {
    state.sidebar.opened = !state.sidebar.opened
    state.sidebar.withoutAnimation = false
    if (state.sidebar.opened) {
      Cookies.set('sidebarStatus', 1)
    } else {
      Cookies.set('sidebarStatus', 0)
    }
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    Cookies.set('sidebarStatus', 0)
    state.sidebar.opened = false
    state.sidebar.withoutAnimation = withoutAnimation
  },
  TOGGLE_DEVICE: (state, device) => {
    state.device = device
  },
  TOGGLE_LANGUAGE: (state, language) => {
    state.language = language
    Cookies.set('language', language)
  },
  TOGGLE_THEME: (state, theme) => {
    state.theme = theme
    Cookies.set('theme', theme)
  },
}

const actions = {
  toggleSideBar({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
  setLanguage({ commit }, language) {
    commit('TOGGLE_LANGUAGE', language)
  },
  setTheme({ commit }, theme) {
    commit('TOGGLE_THEME', theme)
  },
  closeSideBar({ commit }, { withoutAnimation }) {
    commit('CLOSE_SIDEBAR', withoutAnimation)
  },
  toggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device)
  },
  getCount({ commit, dispatch }) {
    unreadList().then((res) => {
      commit('SET_MESSAGECOUNT', res.data.count)
      // if (document.getElementById("myicon-baojing")) {
      //   let badge = document.getElementById("myicon-baojing").childNodes[1];
      //   if (res.alarm_count > 0) {
      //     badge.style.display = "block";
      //     badge.innerHTML = res.alarm_count;
      //   } else {
      //    badge.style.display = "none";
      //    badge.innerHTML = "";
      //   }
      // }
      //  setTimeout(() => {
      //   dispatch("getCount")
      //  }, 10000);
    })
  },
  geteditCount({ commit, dispatch }) {
    uneditList({ page: 1, type: 1 }).then((res) => {
      commit('SET_EDITCOUNT', res.data.count)
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
