export default {
  install(Vue) {
    //禁止短时间内重复点击
    Vue.directive('preventClick', {
      mounted(button, bind) {
        button.addEventListener('click', () => {
          if (!button.disabled) {
            button.disabled = true
            console.log('click', bind);
            setTimeout(() => {
              button.disabled = false
            }, bind.value || 1000 * 5)
          }
        })
      },
    })
  },
}
