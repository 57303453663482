import * as Vue from 'vue'
import Layout from '@/layout'
export default {
    path: '/operate',
    name:'operate',
    component: Layout,
    alwaysShow: true,
    meta: { title: 'menus.operate', icon: 'operate' },
    children: [
      {
        path: 'order',
        name: 'order',
        // component: () => import('@/views/model'),
        component: () => import('@/views/order/list'),
        meta: { title: 'menus.order', icon: 'form' }
      },
      {
        path: 'mps',
        name: 'mps',
        // component: () => import('@/views/model'),
        component: () => import('@/views/plan/MPS'),
        meta: { title: 'menus.mps', icon: 'plan' }
      },
      {
        path: 'production_process',
        name: 'production_process',
        // component: () => import('@/views/model'),
        component: () => import('@/views/plan/production_process'),
        meta: { title: 'menus.product_process', icon: 'process' }
      },
      {
        path: 'mrp',
        name: 'mrp',
        component: () => import('@/views/CRP/list.vue'),
        meta: { title: 'menus.mrp', icon: 'crp' }
      },
      {
        path: 'mrp_view',
        name: 'mrp_view',
        hidden:true,
        component: () => import('@/views/CRP/mrp_view.vue'),
        meta: { title: 'menus.mrp', icon: 'crp' }
      },
      {
        path: 'mrp_add',
        name: 'mrp_add',
        hidden:true,
        component: () => import('@/views/CRP/mrp_add.vue'),
        meta: { title: 'menus.mrp', icon: 'crp' }
      },
      {
        path: 'mrp_edit',
        name: 'mrp_edit',
        hidden:true,
        component: () => import('@/views/CRP/mrp_edit.vue'),
        meta: { title: 'menus.mrp', icon: 'crp' }
      },
      {
        path: 'mrp_other_add',
        name: 'mrp_other_add',
        hidden:true,
        component: () => import('@/views/CRP/mrp_other_add.vue'),
        meta: { title: 'menus.mrp', icon: 'crp' }
      },{
        path: 'mrp_other_edit',
        name: 'mrp_other_edit',
        hidden:true,
        component: () => import('@/views/CRP/mrp_other_edit.vue'),
        meta: { title: 'menus.mrp', icon: 'crp' }
      },


    ]
  }
