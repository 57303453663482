import * as Vue from 'vue'
import Layout from '@/layout'
export default {
  path: '/purchase',
  component: Layout,
  redirect: '/purchase/purchases',
  name: 'purchase',
  alwaysShow: true,
  meta: { title: 'menus.purchase', icon: 'purchase' },
  children: [
    {
      path: '/',
      children: [
        {
          path: 'supplier',
          name:'purchase_supplier',
          component: () => import('@/views/purchase/purchase_contract/suppliers.vue'),
          meta: { title: 'menus.supplier', icon: 'supplier'},
        },
        {
          path: 'supplier/:parent_id(\\d+)/:coderule/',
          name: 'purchase_supplier_file',
          hidden: true,
          subhidden: true,
          component: () => import('@/views/purchase/purchase_contract/contract_file.vue'),
          meta: { title: 'menus.contract', icon: 'purchase', activeMenu: '/purchase/supplier'},
        },
      ],
    },
    {
      path: 'purchases',
      name: 'purchase_purchasing',
      component: Vue.defineAsyncComponent(
        () => import('@/views/CRP/purchasing.vue')
      ),
      meta: {title: 'menus.purchasing_requisition',icon: 'request-refund'},
    },
    {
      path: 'requisition',
      name: 'purchaseInfo',
      hidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/CRP/purdetail.vue')
      ),
      meta: {
        title: 'purchasing_requisition.purchase_requisition',
        icon: 'tech',
        activeMenu: '/purchase/purchases',
      },
    },
    {
      path: 'pending',
      name: 'purchase_pending',
      hidden: true,
      subhidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/CRP/pur_approve.vue')
      ),
      meta: {
        title: 'purchasing_requisition.pending_approval',
        icon: 'tech',
        activeMenu: '/purchase/purchases'
      },
    },
    {
      path: 'purExport',
      name: 'purchase_purExport',
      hidden: true,
      subhidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/CRP/purExport.vue')
      ),
      meta: {
        title: 'purchasing_requisition.pending_approval',
        icon: 'tech',
      },
    },
    {
      path: 'purPrint',
      name: 'purchase_purPrint',
      hidden: true,
      subhidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/CRP/pur_print.vue')
      ),
      meta: {
        title: 'menus.print',
        icon: 'tech',
      },
    },
    {
      path: 'purExcel',
      name: 'purchase_purExcel',
      hidden: true,
      subhidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/CRP/purExcelc.vue')
      ),
      meta: { title: '导出表格', icon: 'crp' },
    },
    {
      path: 'priceCompare',
      name: 'purchase_priceCompare',
      hidden: true,
      subhidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/CRP/priceCompare.vue')
      ),
      meta: { 
        title: 'menus.compare_price', 
        activeMenu: '/purchase/purchases',
        icon: 'crp' 
      },
    },
    {
      path: 'purPM',
      name: 'purchase_purPM',
      hidden: true,
      subhidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/CRP/pur_PM.vue')
      ),
      meta: { 
        title: 'menus.pm', 
        activeMenu: '/purchase/purchases',
        icon: 'crp' 
      },
    },
  ],
}
