<template>
  <el-config-provider :locale="locale">
    <div :class="'app-main lang-'+store.state.app.language ">
      <router-view />
    </div>
  </el-config-provider>
</template>
<script language="ts" setup name="App">
import { computed } from 'vue'
import { useStore } from 'vuex'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import en from 'element-plus/dist/locale/en.mjs'
import ThemeEngine from '@/utils/theme'
const store = useStore();

const locale = computed(() => store.state.app.language === 'zh' ? zhCn : en)
const theme = computed(() => store.state.app.theme)
if (window.ipyxis && window.ipyxis.user && window.ipyxis.user.theme) {
  store.dispatch('app/setTheme', ThemeEngine.Themes[window.ipyxis.user.theme] ? window.ipyxis.user.theme : 'dark blue')
} else {
  if(!ThemeEngine.Themes[theme.value]){
    store.dispatch('app/setTheme', 'dark blue');
  }
}
ThemeEngine.setThemeColor(ThemeEngine.Themes[theme.value])

</script>
<style lang="scss" scoped>
.app-main{
  height: 100%;
  &.lang-zh{
  font-family: 'PingFang SC', "Hiragino Sans GB",  'Microsoft YaHei', sans-serif;
  }
  &.lang-en{
    font-family: 'Arial', "Helvetica Neue", Helvetica, 'Microsoft YaHei', sans-serif;
  }
}

</style>