import * as Vue from 'vue'
import Layout from '@/layout'

export default {
  path: '/techInfo',
  name: 'tech',
  component: Layout,
  redirect: '/techInfo/list/',
  meta: { title: 'menus.techinfo', icon: 'tech' },
  children: [
    {
      path: 'list',
      name: 'techInfo',
      hidden: true,
      // component: () => import('@/views/techInfo/list/index.vue'),
      // path: 'product_folders',
      component: Vue.defineAsyncComponent(
        () => import('@/views/techInfo/product_folders.vue')
      ),
      // meta: {title: '类型', icon: 'tech', showRole: true},
      meta: {
        title: '产品',
        icon: 'tech',
        showRole: false,
        showRoles: true,
      },
      children: [
        // {
        //   path: 'product_folders/:level1(\\d+)/:owner1(\\d+)',
        //   name: 'tech_product_folders',
        //   hidden: true,
        //   component: () => import('@/views/techInfo/product_folders.vue'),
        //   meta: {
        //     title: '产品', icon: 'tech', showRole: false, showRoles: true
        //   },
        //   children: [
        //     {
        //       path: 'info_boards/:level(\\d+)/:owner(\\d+)',
        //       name: 'tech_info_boards',
        //       hidden: true,
        //       component: () => import('@/views/techInfo/info_board.vue'),
        //       meta: {title: '板块', icon: 'tech', showRoles: false, showRole1: true},
        //       children: [
        //         {
        //           path: 'files/:file_id(\\d+)/',
        //           name: 'tech_files',
        //           hidden: true,
        //           component: () => import('@/views/techInfo/files.vue'),
        //           meta: {title: '文件', icon: 'tech', showRole1: false, showRole2: true}
        //         },
        //       ]
        //     },
        //   ]
        // },
        // {
        //   path: 'product_folders/:level1(\\d+)/:owner1(\\d+)',
        //   name: 'tech_product_folders',
        //   hidden: true,
        //   component: () => import('@/views/techInfo/product_folders.vue'),
        //   meta: {
        //     title: '产品', icon: 'tech', showRole: false, showRoles: true
        //   },
        //   children: [
        {
          path: 'info_boards/:level(\\d+)/:owner(\\d+)',
          name: 'tech_info_boards',
          hidden: true,
          component: Vue.defineAsyncComponent(
            () => import('@/views/techInfo/info_board.vue')
          ),
          meta: {
            title: '板块',
            icon: 'tech',
            showRoles: false,
            showRole1: true,
          },
          children: [
            {
              path: 'files/:file_id(\\d+)/',
              name: 'tech_files',
              hidden: true,
              component: Vue.defineAsyncComponent(
                () => import('@/views/techInfo/files.vue')
              ),
              meta: {
                title: '文件',
                icon: 'tech',
                showRole1: false,
                showRole2: true,
              },
            },
          ],
        },

        // ]
        // },
      ],
    },
  ],
}
