/* eslint-disable*/
import axios from 'axios'
import { ElMessageBox as MessageBox, ElMessage as Message } from 'element-plus'
import store from '@/store'
import { getToken } from '@/utils/auth'
import common from './common.js'
import Cookies from 'js-cookie'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
})

// request interceptor
service.interceptors.request.use(
  (config) => {
    if (config.url === '/o/token/') {
      let client_ida = '7BFSdvGdSYBQ583WoYHsMyfplTiq7NLMQGvOHRsa'
      let client_secreta =
        'N6yt7hSa1bWSVG29LaYjVQ8FNaUfVMqOcu6dSKKgRKE4yyVyHJbKLi3Nqf5tQPqQGeuZz6en9x9z7IZpNUn1kfKjwXw84xjepwVtwf15JlsBfIYVAbZdbX71VcjrRcvi'
      config.headers = {
        ...config.headers,
        Authorization: 'Basic ' + common.encodex(client_ida + ':' + client_secreta),
        // 'content-type': 'application/json;charset=utf-8',
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    } else {
      if (store.getters.token) {
        if (config.params && 'dload' in config.params) {
          config.responseType = 'blob'
        }
        config.headers['Authorization'] = 'Bearer ' + getToken()
        config.headers['X-CSRFToken'] = Cookies.get('csrftoken')
      }
    }
    return config
  },
  (error) => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    // return Promise.reject(error)
    let status = error.response.status
    console.log('status', status)
    if (status == 401) {
      Message({
        message: '认证失败请重新登录',
        type: 'error',
        duration: 5 * 1000,
      })
      store.dispatch('user/logout').then(() => {
        // router.replace({ path: "/login" });
        if (process.env.NODE_ENV === 'development') {
          next(`/login?redirect=${to.path}`)
        } else {
          window.location.href = process.env.NODE_ENV === 'development' ? `/login` : '/login_erp/'
        }
      })
      return Promise.reject(error)
    } else {
      return Promise.reject(error)
    }
  }
)

export default service
