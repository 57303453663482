import * as Vue from 'vue'
import Layout from '@/layout'
export default {
  path: '/materials',
  name: 'materials',
  component: Layout,
  alwaysShow: true,
  meta: { title: 'menus.material', icon: 'material' },
  children: [
    {
      path: 'company',
      name: 'materials_company',
      component: Vue.defineAsyncComponent(
        () => import('@/views/customer/index.vue')
      ),
      meta: { title: 'menus.customer', icon: 'customer' },
    },
    {
      path: 'companyinfo',
      name: 'materials_companyinfo',
      hidden: true,
      subhidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/customer/companyInfo.vue')
      ),
      meta: {
        title: '公司管理详情',
        activeMenu: '/materials',
        activeTab: 'index',
      },
    },
    {
      path: 'contact',
      name: 'materials_contact',
      component: Vue.defineAsyncComponent(
        () => import('@/views/customer/contacts.vue')
      ),
      meta: { title: 'menus.contact', icon: 'customer-2' },
    },
    {
      path: 'contactsinfo',
      name: 'materials_contactsinfo',
      hidden: true,
      subhidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/customer/contactsInfo.vue')
      ),
      meta: {
        title: '联系人管理详情',
        activeMenu: '/materials',
        activeTab: 'index',
      },
    },
    // {
    //   path: 'manual',
    //   name: 'material_manual',
    //   component: () => import('@/views/customer/manual.vue'),
    //   meta: { title: 'menus.instruction', icon: 'tech' }
    // }
  ],
}
