import * as Vue from 'vue'
import Layout from '@/layout'
export default {
  path: '/product',
  name: 'model',
  component: Layout,
  redirect: '/product/detail',
  // name: 'Product',
  meta: { title: 'menus.product', icon: 'product-management' },
  children: [
    {
      path: 'models',
      name: 'productmodel',
      component: Vue.defineAsyncComponent(
        () => import('@/views/model/models.vue')
      ),
      meta: { title: 'menus.product_m', icon: 'model', affix: true },
    },

    {
      path: 'detail',
      name: 'model_detail_list',
      component: Vue.defineAsyncComponent(
        () => import('@/views/model/product_detail_list.vue')
      ),
      meta: { title: 'menus.model', icon: 'detail', affix: true },
    },
    // {
    //   path: 'pnrelease',
    //   name: 'modelpnrelease',
    //   component: () => import('@/views/model/release.vue'),
    //   meta: { title: 'release.free', icon: 'chanpin',affix: true }
    // },
    // {
    //   path: 'pnrelease_det',
    //   name: 'pnrelease_det',
    //   hidden: true,
    //   component: () => import('@/views/model/release_det.vue'),
    //   meta: { title: 'release.freedet', icon: 'chanpin',affix: true }
    // },
    // {
    //   path: 'change',
    //   name: 'pnchange',
    //   component: () => import('@/views/model/change_notice.vue'),
    //   meta: { title: 'release.pro_change', icon: 'chanpin',affix: true }
    // },
    // // {
    // //   path: 'change_det',
    // //   name: 'change_det',
    // //   hidden: true,
    // //   component: () => import('@/views/model/change_det.vue'),
    // //   meta: { title: '更改详情', icon: 'chanpin',affix: true }
    // // },
    // {
    //   path: 'change_add',
    //   name: 'change_add',
    //   hidden: true,
    //   component: () => import('@/views/model/change_add.vue'),
    //   meta: { title: 'release.proadd', icon: 'chanpin',affix: true }
    // },
    // {
    //   path: 'change_view',
    //   name: 'change_view',
    //   hidden: true,
    //   component: () => import('@/views/model/change_view.vue'),
    //   meta: { title: 'release.prodet', icon: 'chanpin',affix: true }
    // },
    // {
    //   path: 'change_edit',
    //   name: 'change_edit',
    //   hidden: true,
    //   component: () => import('@/views/model/change_edit.vue'),
    //   meta: { title: 'release.proedit', icon: 'chanpin',affix: true }
    // },
    // {
    //   path: 'change_approve',
    //   name: 'change_approve',
    //   hidden: true,
    //   component: () => import('@/views/model/change_approve.vue'),
    //   meta: { title: 'release.proapprove', icon: 'chanpin',affix: true }
    // },
    // {
    //   path: 'change_approve_detail',
    //   name: 'change_approve_detail',
    //   hidden: true,
    //   component: () => import('@/views/model/change_approve_detail.vue'),
    //   meta: { title: 'release.proapprove', icon: 'chanpin',affix: true }
    // },
    // {
    //   path: 'softchange',
    //   name: 'softchange',
    //   component: () => import('@/views/model/software_change_notice.vue'),
    //   meta: { title: 'soft_change.pro_soft_change', icon: 'chanpin',affix: true }
    // },
    // {
    //   path: 'softchange_add',
    //   name: 'softchange_add',
    //   hidden: true,
    //   component: () => import('@/views/model/soft_change_add.vue'),
    //   meta: { title: 'release.proadd', icon: 'chanpin',affix: true }
    // },
    // {
    //   path: 'softchange_edit',
    //   name: 'softchange_edit',
    //   hidden: true,
    //   component: () => import('@/views/model/soft_change_edit.vue'),
    //   meta: { title: 'release.proadd', icon: 'chanpin',affix: true }
    // },
    // {
    //   path: 'softchange_view',
    //   name: 'softchange_view',
    //   hidden: true,
    //   component: () => import('@/views/model/soft_change_view.vue'),
    //   meta: { title: 'release.proadd', icon: 'chanpin',affix: true }
    // },
    {
      path: 'model/:id(\\d+)',
      name: 'modeldetailInfo',
      hidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/model/product_detail_view.vue')
      ),
      meta: { title: 'menus.model', noCache: true, activeMenu: '/product/detail' },
    },
    {
      path: 'model/:id(\\d+)/edit',
      name: 'editmodelInfo',
      hidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/model/product_detail_edit.vue')
      ),
      meta: { title: 'Model', noCache: true, activeMenu: '/product/models' , activeMenu: '/product/detail'},
    },
    {
      path: 'duplicatemodelInfo/:id(\\d+)',
      name: 'duplicatemodelInfo',
      hidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/model/duplicate.vue')
      ),
      meta: { title: 'Model', noCache: true, activeMenu: '/product/models' },
    },
    {
      path: 'category',
      name: 'modelcategory',
      component: Vue.defineAsyncComponent(
        () => import('@/views/model/pn_category.vue')
      ),
      meta: { title: 'menus.model_category', icon: 'category', affix: true },
    },
    {
      path: 'labelname/:id(\\d+)',
      name: 'modellabelname',
      hidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/model/pn_labelname.vue')
      ),
      meta: { title: 'menus.model_category', icon: 'chanpin', affix: true , activeMenu: '/product/category'},
    },
    {
      path: 'pending',
      name: 'modelpending',
      hidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/model/pn_approve.vue')
      ),
      meta: { title: 'model.approval_pending', noCache: true, activeMenu: '/product/detail' },
    },
    {
      path: 'log',
      name: 'modelLog',
      hidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/model/log.vue')
      ),
      meta: { title: 'Model', noCache: true, activeMenu: '/product/models' },
    },
    {
      path: 'brand',
      name: 'modelbrand',
      hidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/model/product_detail_brand_list.vue')
      ),
      meta: { 
        title: 'model.brand', 
        activeMenu: '/product/models' 
      },
    },
    // {
    //   path: 'priceManagement',
    //   name: 'priceManagement',
    //   component: () => import('@/views/price/product_price.vue'),
    //   meta: { title: 'menus.price_management', icon: 'chanpin',affix: true }
    // },
    // {
    //   path: 'priceNormalQuote',
    //   name: 'priceNormalQuote',
    //   component: () => import('@/views/price/product_normal_quote.vue'),
    //   meta: { title: 'menus.normal', icon: 'chanpin',affix: true }
    // },
    // {
    //   path: 'normalquote',
    //   name: 'normalquote',
    //   hidden: true,
    //   component: () => import('@/views/price/normal_quote.vue'),
    //   meta: { title: 'Normal Quote', noCache: true, activeMenu: '/product/quote' }
    // },
    // {
    //   path: 'normalquote/:id(\\d+)',
    //   name: 'normalquotedetail',
    //   hidden: true,
    //   component: () => import('@/views/price/normal_quote.vue'),
    //   meta: { title: 'Normal Quote', noCache: true, activeMenu: '/product/quote' }
    // },
    // {
    //   path: 'priceSpecialQuote',
    //   name: 'priceSpecialQuote',
    //   component: () => import('@/views/price/product_special_quote.vue'),
    //   meta: { title: 'menus.special', icon: 'chanpin',affix: true }
    // },
    // {
    //   path: 'specialquote',
    //   name: 'specialquote',
    //   hidden: true,
    //   component: () => import('@/views/price/special_quote.vue'),
    //   meta: { title: 'Special Quote', noCache: true, activeMenu: '/product/quote' }
    // },
    // {
    //   path: 'specialquote/:id(\\d+)',
    //   name: 'specialquotedetail',
    //   hidden: true,
    //   component: () => import('@/views/price/special_quote.vue'),
    //   meta: { title: 'Special Quote', noCache: true, activeMenu: '/product/quote' }
    // },
  ],
}
