const getters = {
  sidebar: (state) => state.app.sidebar,
  device: (state) => state.app.device,
  token: (state) => state.user.token,
  avatar: (state) => state.user.avatar,
  name: (state) => state.user.name,
  unReadCount: (state) => state.app.count,
  unEditCount: (state) => state.app.uneditcount,
  routes: (state) => state.permission.routes, //建立权限模块下的快捷访问
  language: (state) => state.app.language,
  theme: (state) => state.app.theme,
}
export default getters
