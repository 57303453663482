import * as Vue from 'vue'
import Layout from '@/layout'
export default {
  path: '/quotation',
  name: 'quote',
  component: Layout,
  meta: { title: 'menus.quote', icon: 'money-check' },
  children: [
    {
      path: 'list',
      name: 'priceNormalquote',
      component: Vue.defineAsyncComponent(
        () => import('@/views/price/product_normal_quote.vue')
      ),
      meta: { title: 'menus.normal', icon: 'quotation-text', affix: true },
    },
    {
      path: 'regular/add',
      name: 'normalquote',
      hidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/price/normal_quote.vue')
      ),
      meta: {
        title: 'Normal Quote',
        noCache: true,
        activeMenu: '/quotation/list',
      },
    },
    {
      path: 'regular/:id(\\d+)/',
      name: 'normalquote_view',
      hidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/price/normal_quote_view.vue')
      ),
      meta: {
        title: 'Normal Quote',
        noCache: true,
        activeMenu: '/quotation/list',
      },
    },
    {
      path: 'regular/:id(\\d+)/edit',
      name: 'normalquotedetail',
      hidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/price/normal_quote.vue')
      ),
      meta: {
        title: 'Normal Quote',
        noCache: true,
        activeMenu: '/quotation/list',
      },
    },
    {
      path: 'specials',
      name: 'priceSpecialquote',
      component: Vue.defineAsyncComponent(
        () => import('@/views/price/product_special_quote.vue')
      ),
      meta: { title: 'menus.special', icon: 'quotation-text-special', affix: true },
    },
    {
      path: 'special',
      name: 'specialquote',
      hidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/price/special_quote.vue')
      ),
      meta: {
        title: 'Special Quote',
        noCache: true,
        activeMenu: '/quotation/specials',
      },
    },
    {
      path: 'special/:id(\\d+)',
      name: 'specialquote_view',
      hidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/price/special_quote_view.vue')
      ),
      meta: {
        title: 'Special Quote',
        noCache: true,
        activeMenu: '/quotation/specials',
      },
    },
    {
      path: 'special/:id(\\d+)/edit',
      name: 'specialquotedetail',
      hidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/price/special_quote.vue')
      ),
      meta: {
        title: 'Special Quote',
        noCache: true,
        activeMenu: '/quotation/specials',
      },
    },
  ],
}
