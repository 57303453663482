import request from '@/utils/request'
// 用户认证API

export function login(data) {
  const postdata = new FormData()
  postdata.append('username', data['username'])
  postdata.append('password', data['password'])
  postdata.append('grant_type', 'password')
  return request({
    url: '/o/token/',
    method: 'post',
    data: postdata,
  })
}

export function getInfo(token) {
  return request({
    url: '/api/profile/',
    method: 'get',
    data: { token },
  })
}
export function getUserPermission(token) {
  return request({
    url: '/api/permission/user/',
    method: 'get',
    data: { token },
  })
}

export function logout(token) {
  return request({
    url: '/o/revoke_token/',
    method: 'post',
    params: { token },
  })
}
