import { login, logout, getInfo, getUserPermission } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { resetRouter } from '@/router'

const state = {
  token: getToken(),
  name: '',
  avatar: '',
  userInfo: {}, //这里定义一个空对象
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_UserInfo(state, result) {
    // 更新一个对象
    state.userInfo = result
  },
  removeUserInfo(state) {
    state.userInfo = {}
  },
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password })
        .then((response) => {
          const { data } = response
          commit('SET_TOKEN', data.access_token)
          setToken(data.access_token)
          var token = getToken()
          getInfo(token)
            .then((response) => {
              const { data } = response
              if (!data) {
                reject('Verification failed, please Login again.')
              }
              localStorage.setItem('username', data.username)
              localStorage.setItem('userid', data.id)
              localStorage.setItem('userrole', data.type)
              localStorage.setItem('userdep', data.dep)
              commit('SET_NAME', data.username)
              resolve(data)
            })
            .catch((error) => {
              reject(error)
            })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      // logout(state.token).then(() => {
      commit('SET_TOKEN', '')
      commit('removeUserInfo')
      removeToken()
      // 重置路由
      // resetRouter()
      window.location.href = process.env.NODE_ENV === 'development' ? `/login` : '/login_erp/'
      commit('permission/SET_ROUTES', [], { root: true })
      localStorage.removeItem('username')
      localStorage.removeItem('userid')
      resolve()
      //   }).catch(error => {
      //     reject(error)
      //   })
    })
  },
  //获取用户资料
  async getUserInfo(context, token) {
    const res = await getUserPermission(token)
    const result = res.data
    console.log('result', result)
    context.commit('SET_UserInfo', result) //提交到mutations
    return result //这里为什么要return呢，这里是给我们后期做权限的时候留下伏笔
  },
  async getLoginUserInfo(context, token) {
    const res = await getInfo(token)
    const result = res.data
    return result
  },
  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit('SET_TOKEN', '')
      removeToken()
      resolve()
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
