import * as Vue from 'vue'
import Layout from '@/layout'
export default {
  path: '/rma',
  component: Layout,
  name: 'rma',
  redirect: '/rma/list',
  children: [
    {
      path: 'list',
      name: 'rmaservice',
      alwaysShow: true,
      component: Vue.defineAsyncComponent(() => import('@/views/RMA/rmas')),
      meta: { title: 'menus.rma', icon: 'shouhouwuyou' },
    },
    {
      path: 'rmainfo',
      name: 'rmainfo',
      hidden: true,
      subhidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/RMA/rmainfo.vue')
      ),
      meta: {
        title: 'RMAInfo',
        activeMenu: '/rma/list',
        activeTab: 'index',
      },
    },
    {
      path: 'rmasingle',
      name: 'rmasingle',
      hidden: true,
      subhidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/RMA/rmasingle.vue')
      ),
      meta: {
        title: 'Service Report',
        activeMenu: '/rma/list',
        activeTab: 'index',
      },
    },
    {
      path: 'rmasingleList',
      name: 'rmasingleList',
      hidden: true,
      subhidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/RMA/rmasingleList.vue')
      ),
      meta: {
        title: 'rmasingleList',
        activeMenu: '/rma/list',
        activeTab: 'index',
      },
    },
    // {
    //   path: 'list',
    //   name: 'rmaservice',
    //   alwaysShow: true,
    //   component: () => import('@/views/RMA/list'),
    //   meta: { title: 'menus.rma', icon: 'shouhouwuyou' },
    // },
    // {
    //   path: 'info/:id(\\d+)',
    //   name: 'rmainfo',
    //   hidden: true,
    //   component: () => import('@/views/RMA/detail'),
    //   meta: { title: 'Information', noCache: true, activeMenu: '/rma/list' }
    // }
  ],
}
