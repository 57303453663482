/* eslint-disable */
import router from './router'
import store from './store'
import { ElMessage as Message } from 'element-plus'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login'] // no redirect whitelist

//单文件js使用i18n
import {createI18n} from 'vue-i18n'
import { useI18n } from 'vue-i18n'
import * as Vue from 'vue'
// Vue.use(VueI18n)

import zh from '../src/lang/zh_CH'
import en from '../src/lang/en'
const i18n = createI18n({
  locale: localStorage.getItem('local') || 'zh',
  messages: {
    zh,
    en,
  },
})
const { locale, t } = i18n.global
router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start()

  // set page title
  document.title = getPageTitle(to.meta.title? t(to.meta.title): to.meta.title)

  // determine whether the user has logged in
  const hasToken = getToken()
  if (hasToken) {
    if (to.path === '/login_erp/') {
      // if is logged in, redirect to the home page
      next({ path: '/' })
      NProgress.done()
    } else {
      if (!store.state.user.userInfo.menus) {
        try {
          const roles = await store.dispatch('user/getUserInfo')
          const accessRoutes = await store.dispatch(
            'permission/generateRoutes',
            roles.menus
          )
          
          accessRoutes.forEach(accessRoute => router.addRoute(accessRoute))
          router.addRoute({ path: '/:catchAll(.*)', redirect: '/404', hidden: true })

          const userinfo = await store.dispatch('user/getLoginUserInfo')
          console.log('userinfo', userinfo)
          localStorage.setItem('username', userinfo.username)
          localStorage.setItem('userid', userinfo.id)
          localStorage.setItem('userrole', userinfo.type)
          localStorage.setItem('userdep', userinfo.dep)

          // next(to.path) // addRoutes  必须 用 next(地址) 不能用next()
          // next(to.fullPath) // addRoutes  必须 用 next(地址) 不能用next()
          if (to.fullPath != '/notice/message') {
            next(to.fullPath)
          } else {
            next('/?userId=' + userinfo.id)
          }
          //   相当于跳到对应的地址  相当于多做一次跳转 为什么要多做一次跳转
          // 进门了，但是进门之后我要去的地方的路还没有铺好，直接走，掉坑里，多做一次跳转，再从门外往里进一次，跳转之前 把路铺好，再次进来的时候，路就铺好了
          // next()
        } catch (error) {
          // remove token and go to login page to re-login
          await store.dispatch('user/resetToken')
          Message.error(error || 'Has Error')
          window.location.href = process.env.NODE_ENV === 'development' ? `/login` : '/login_erp/'
          // next(`/login?redirect=${to.path}`)
          NProgress.done()
        }

        // next()
      }
       else {
        next()
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      if (process.env.NODE_ENV === 'development') {
        next(`/login?redirect=${to.path}`)
      } else {
          window.location.href = process.env.NODE_ENV === 'development' ? `/login` : '/login_erp/'
      }
      // next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
