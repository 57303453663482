// 代码载取来至：https://gitee.com/lolicode/scui/blob/master/src/utils/color.js
export default {
  Themes:
  {
    'dark blue': {
      color:{
        'primary': '#004990',
        'danger': '#CB272D',
        'success': '#00B42A',
      },
      'dark-blue': '#004990',
      'light-blue': '#1202ff',
      'menu-background': '#121213',
      'sub-menu-background': '#1f2d3d',
      'sidebar-width': '14rem',
      'menu-text-color': '#fff',
      'menu-text-active-color':'#fff',
      'text-color': '#fff',
      'light-blue-3': '#6086ff',
      'sub-table-color': '#6086ff',
      'sub-table-text-color': '#fff',
      'font-size-base': '14px',
      'text-color-placeholder': '#a8abb2',
      'tab-text-color':'#333',
    },
    'light blue':{
      color:
      {
        'primary': '#1202ff',
        'danger': '#CB272D',
        'success': '#00B42A',
      },
      'dark-blue': '#1202ff',
      'light-blue': '#6086ff',
      'menu-background': '#121213',
      'sub-menu-background': '#1f2d3d',
      'sidebar-width': '14rem',
      'menu-text-color': '#fff',
      'menu-text-active-color':'#fff',
      'text-color': '#fff',
      'light-blue-3': '#6086ff',
      'sub-table-color': '#6086ff',
      'font-size-base': '14px',
      'text-color-placeholder': '#a8abb2',
      'tab-text-color':'#333',
    },
    'dark green':{
      color:
      {
        'primary': '#004D1C',
        'danger': '#ff0202',
        'success': '#67c23a',
      },
      'dark-blue': '#004D1C',
      'light-blue': '#23C343',
      'menu-background': '#004D1C',
      'sub-menu-background': '#006622',
      'sidebar-width': '14rem',
      'text-color': '#fff',
      'menu-text-color': '#fff',
      'menu-text-active-color':'#fff',
      'sub-table-color': '#4CD263',
      'sub-table-text-color': '#333',
      'font-size-base': '14px',
      'text-color-placeholder': '#a8abb2',
      'tab-text-color':'#333',
    },
    'light green':{
      color:
      {
        'primary': '#23C343',
        'danger': '#ff0202',
        'success': '#37D4CF',
      },
      'dark-blue': '#7BE188',
      'light-blue': '#AFF0B5',
      'menu-background': '#AFF0B5',
      'sub-menu-background': '#E8FFEA',
      'sidebar-width': '14rem',
      'sub-table-color': '#E8FFEA',
      'text-color': '#000',
      'menu-text-color': '#000',
      'menu-text-active-color':'#333',
      'sub-table-text-color': '#333',
      'font-size-base': '14px',
      'text-color-placeholder': '#a8abb2',
      'tab-text-color':'#333',
    },
    'dark purple':{
      color:
      {
        'primary': '#551DB0',
        'danger': '#CB272D',
        'success': '#00B42A',
      },
      'dark-blue': '#16004D',
      'light-blue': '#722ED1',
      'menu-background': '#722ED1',
      'sub-menu-background': '#551DB0',
      'sidebar-width': '14rem',
      'sub-table-color': '#551DB0',
      'text-color': '#FFF',
      'menu-text-color': '#FFF',
      'menu-text-active-color':'#fff',
      'sub-table-text-color': '#fff',
      'font-size-base': '14px',
      'text-color-placeholder': '#a8abb2',
      'tab-text-color':'#333',
    },
    'light purple':{
      color:
      {
        'primary': '#A871E3',
        'danger': '#ff0202',
        'success': '#67c23a',
      },
      'dark-blue': '#8D4EDA',
      'light-blue': '#F5E8FF',
      'menu-background': '#A871E3',
      'sub-menu-background': '#DDBEF6',
      'sidebar-width': '14rem',
      'sub-table-color': '#DDBEF6',
      'text-color': '#000',
      'menu-text-color': '#000',
      'menu-text-active-color':'#333',
      'font-size-base': '14px',
      'text-color-placeholder': '#a8abb2',
      'tab-text-color':'#333',
    },
    'dark grey':{
      color:
      {
        'primary': '#272e3b',
        'danger': '#ff0202',
        'success': '#67c23a',
      },
      'dark-blue': '#1d2129',
      'light-blue': '#6b7785',
      'menu-background': '#272e3b',
      'sub-menu-background': '#4e5969',
      'sidebar-width': '14rem',
      'sub-table-color': '#4e5969',
      'text-color': '#fff',
      'menu-text-color': '#fff',
      'menu-text-active-color':'#fff',
      'font-size-base': '14px',
      'sub-table-text-color': '#fff',
      'text-color-placeholder': '#a8abb2',
      'tab-text-color':'#333',
    },
    'light grey':{
      color:
      {
        'primary': '#4080FF',
        'danger': '#ff0202',
        'success': '#67c23a',
      },
      'dark-blue': '#86909c',
      'light-blue': '#e5e6eb',
      'menu-background': '#a9aeb8',
      'sub-menu-background': '#c9cdd4',
      'sidebar-width': '14rem',
      'sub-table-color': '#c9cdd4',
      'text-color': '#000',
      'menu-text-color': '#000',
      'menu-text-active-color':'#333',
      'font-size-base': '14px',
      'sub-table-text-color': '#333',
      'text-color-placeholder': '#a8abb2',
      'tab-text-color':'#333',
    }
},
//hex颜色转rgb颜色
HexToRgb(str) {
  str = str.replace("#", "")
  var hxs = str.match(/../g)
  for (var i = 0; i < 3; i++) hxs[i] = parseInt(hxs[i], 16)
  return hxs
},
//rgb颜色转hex颜色
RgbToHex(a, b, c) {
  var hexs = [a.toString(16), b.toString(16), c.toString(16)]
  for (var i = 0; i < 3; i++) {
    if (hexs[i].length == 1) hexs[i] = "0" + hexs[i]
  }
  return "#" + hexs.join("");
},
//加深
darken(color, level) {
  var rgbc = this.HexToRgb(color)
  for (var i = 0; i < 3; i++) rgbc[i] = Math.floor(rgbc[i] * (1 - level))
  return this.RgbToHex(rgbc[0], rgbc[1], rgbc[2])
},
//变淡
lighten(color, level) {
  var rgbc = this.HexToRgb(color)
  for (var i = 0; i < 3; i++) rgbc[i] = Math.floor((255 - rgbc[i]) * level + rgbc[i])
  return this.RgbToHex(rgbc[0], rgbc[1], rgbc[2])
},
setThemeColor(colorMap) {
  let _namespace = "pyxis";
  Object.keys(colorMap).forEach((key) => {
    if (key == 'color') {
      Object.keys(colorMap[key]).forEach((name) => {
        this.setPropertyColor(`--${_namespace}-${key}-${name}`, colorMap[key][name]);
        this.themeColorGradient(`--el-${key}-${name}-light-#level#`, 'lighten', colorMap[key][name]);
        this.setPropertyColor(`--el-${key}-${name}-dark-2`, colorMap[key][name],"darken");
      });
    }
    else {
      this.setPropertyValue(`--${_namespace}-${key}`, colorMap[key]);
    }
  });
},

setPropertyValue(varName, value) {
  document.documentElement.style.setProperty(
    varName,
    value
  );
},
/**
 * 将css3变量设置到document中方便全局调用
 */
setPropertyColor(varName, color, funName, level) {
  level = level ? level : 0;
  this.darken(color, level / 10);
  funName = funName ? funName : "lighten";
  document.documentElement.style.setProperty(
    varName,
    this[funName](color, level / 10)
  );
},

/**
 * 生成主色的其余渐变色并修改对应CSS3变量值
 */
themeColorGradient(varName, funName, themeColor, themeLevel) {
  themeColor = themeColor ? themeColor : '#409eff';
  themeLevel = themeLevel ? themeLevel : [3, 5, 7, 8, 9];

  themeLevel.forEach((level) =>{
    this.setPropertyColor(
      varName.replace("#level#", level),
      themeColor,
      funName,
      level
    );
  });
}
};
