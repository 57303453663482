import * as Vue from 'vue'
import Layout from '@/layout'
export default {
  path: '/quality',
  name: 'quality',
  component: Layout,
  meta: { title: 'menus.inspection', icon: 'quality-supervision' },
  children: [
    {
      path: 'list',
      name: 'quality',
      component: Vue.defineAsyncComponent(
        () => import('@/views/quality/index.vue')
      ),
      meta: { title: 'menus.quality', icon: 'quality' },
    },
    {
      path: 'iqc',
      name: 'qualityIQC',
      component: Vue.defineAsyncComponent(
        () => import('@/views/quality/iqc.vue')
      ),
      meta: { title: 'menus.iqc', icon: 'inspection', activeMenu: '/quality/list' },
    },
    {
      path: 'record',
      name: 'qualityRecord',
      component: Vue.defineAsyncComponent(
        () => import('@/views/quality/record.vue')
      ),
      meta: {
        title: 'menus.maintenance',
        icon: 'maintenance',
        activeMenu: '/quality/list',
      },
    },
    {
      path: 'process_defect',
      name: 'quality_process_defect',
      component: Vue.defineAsyncComponent(
        () => import('@/views/quality/process_defect.vue')
      ),
      meta: {
        title: 'menus.process_defect',
        icon: 'defect',
        activeMenu: '/quality/defect',
      },
    },
    {
      path: 'feedback',
      name: 'quality_feedback',
      component: Vue.defineAsyncComponent(
        () => import('@/views/quality/feedback.vue')
      ),
      meta: {
        title: 'menus.feedback',
        icon: 'feedback',
        activeMenu: '/quality/feedback',
      },
    },
    {
      path: 'test',
      name: 'quality_test',
      component: Vue.defineAsyncComponent(
        () => import('@/views/test/index.vue')
      ),
      meta: { title: 'menus.test', icon: 'equipment', activeMenu: '/quality/test' },
    },
    {
      path: 'add_test/:id(\\d+)/:type(\\d+)',
      name: 'quality_add_test',
      hidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/test/spreadsheet.vue')
      ),
      meta: {
        title: 'menus.add_test',
        icon: 'tech',
        activeMenu: '/quality/test',
      },
    },
    {
      path: 'test_detail/:id(\\d+)/',
      name: 'quality_test_detail',
      hidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/test/detail.vue')
      ),
      meta: {
        title: 'menus.test_detail',
        icon: 'tech',
        activeMenu: '/quality/test',
      },
    },
    {
      path: 'test_edit/:id(\\d+)/',
      name: 'quality_test_edit',
      hidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/test/edit.vue')
      ),
      meta: {
        title: 'menus.test_edit',
        icon: 'tech',
        activeMenu: '/quality/test',
      },
    },
    {
      path: 'test_copy/:id(\\d+)/:type(\\d+)',
      name: 'quality_test_copy',
      hidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/test/copy.vue')
      ),
      meta: {
        title: 'menus.test_copy',
        icon: 'tech',
        activeMenu: '/quality/test',
      },
    },
    {
      path: 'test_addpre_delivery',
      name: 'quality_test_addpre_delivery',
      hidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/test/addpre-delivery.vue')
      ),
      meta: {
        title: 'menus.oqc_template',
        icon: 'tech',
        activeMenu: '/quality/test',
      },
    },
    {
      path: 'test_sgs_templates',
      name: 'quality_test_sgs_templates',
      hidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/test/sgs_templates.vue')
      ),
      meta: {
        title: 'menus.oqc_template',
        icon: 'tech',
        activeMenu: '/quality/test',
      },
    },
    {
      path: 'edit_sgs_template/:id(\\d+)/',
      name: 'quality_test_ edit_sgs_template',
      hidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/test/edit_sgs_template.vue')
      ),
      meta: {
        title: 'menus.oqc_template',
        icon: 'tech',
        activeMenu: '/quality/test',
      },
    },
    {
      path: 'scrapped',
      name: 'quality_scrapped',
      component: Vue.defineAsyncComponent(
        () => import('@/views/quality/scrapped.vue')
      ),
      meta: {
        title: 'menus.SN_scrapped',
        icon: 'broken',
        activeMenu: '/quality/list',
      },
    },
  ],
}
