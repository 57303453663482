import * as Vue from 'vue'
import Layout from '@/layout'
export default {
  path: '/craft',
  component: Layout,
  name: 'craft', //工艺
  alwaysShow: true,
  meta: { title: 'menus.craft', icon: 'craft' },
  children: [
    {
      path: 'feedback',
      name: 'craft_feedback',
      component: Vue.defineAsyncComponent(
        () => import('@/views/craft/craft_feedback.vue')
      ),
      meta: { title: 'menus.craft_feedback', icon: 'user-feedback' },
    },
    {
      path: 'sop',
      name: 'craft_sop',
      component: Vue.defineAsyncComponent(
        () => import('@/views/craft/sop.vue')
      ),
      meta: { title: 'menus.SOP', icon: 'operation' },
    },
    {
      path: 'sop_file',
      name: 'craft_sop_file',
      hidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/craft/sop_file.vue')
      ),
      meta: { title: 'menus.SOP_file', icon: 'operation-record' },
    },
    {
      path: 'process_test_doc',
      name: 'process_test_doc',
      component: Vue.defineAsyncComponent(
        () => import('@/views/craft/process_test_documents.vue')
      ),
      meta: { title: 'menus.process_test_documents', icon: 'operation-record' },
    },
  ],
}
