import * as Vue from 'vue'
import Layout from '@/layout'
export default {
  path: '/okr',
  name: 'okr',
  component: Layout,
  children: [
    {
      path: '',
      name: 'okr-list',
      component: Vue.defineAsyncComponent(
        () => import('@/views/OKR/index.vue')
      ),
      meta: { title: 'menus.okr', icon: 'result' },
    },
    {
      path: 'info/:id(\\d+)',
      name: 'okr-Info',
      hidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/OKR/detail.vue')
      ),
      meta: { title: 'OKRInfo', noCache: true, activeMenu: '/okr' },
    },
  ],
}
