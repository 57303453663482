import * as Vue from 'vue'
import Layout from '@/layout'
export default {
  path: '/plm',
  component: Layout,
  name: 'plm', //plm
  alwaysShow: true,
  meta: { title: 'menus.PLM', icon: 'plm' },
  redirect: to => {
    return {name: 'plmpnrelease'}
  },

  children: [
    {
      path: 'pnrelease',
      name: 'modelpnrelease',
      component: Vue.defineAsyncComponent(
        // () => import('@/views/model/release.vue')
        () => import('@/views/model/number.vue')
      ),
      // alias:[''],
      meta: { title: 'release.free', icon: 'publish', affix: true, },
    },
    {
      path: 'pnrelease_det',
      name: 'modelpnrelease_det',
      hidden: true,
      component: Vue.defineAsyncComponent(
        () => import('@/views/model/release_det.vue')
      ),
      meta: { title: 'release.freedet', icon: 'chanpin', affix: true, activeMenu:"/plm/pnrelease"},
    },
    {
      path: 'changes',
      name: 'pnchanges',
      redirect: {name: 'change_list'},
      meta: { title: 'release.pro_change', icon: 'changes', affix: true, 
    },
      children: [
        {
          path: 'list',
          name: 'pnchanges_list',
          component: Vue.defineAsyncComponent(
            () => import('@/views/model/hardware_changes.vue')
          ),
          meta: { title: 'release.pro_change', icon: 'changes', affix: true, activeMenu:"/plm/changes/list"},
        },
        {
          path: 'add',
          name: 'pnchanges_add',
          hidden: true,
          component: Vue.defineAsyncComponent(
            () => import('@/views/model/change_add.vue')
          ),
          meta: { title: 'release.proadd', icon: 'chanpin', affix: true, activeMenu:"/plm/changes/list", showRoles:false },
        },
        {
          path: ':id(\\d+)',
          name: 'pnchanges_view',
          hidden: true,
          component: Vue.defineAsyncComponent(
            () => import('@/views/model/change_view.vue')
          ),
          meta: { title: 'release.prodet', icon: 'chanpin', affix: true, activeMenu:"/plm/changes/list" },
        },
        {
          path: ':id(\\d+)/edit',
          name: 'pnchanges_edit',
          hidden: true,
          component: Vue.defineAsyncComponent(
            () => import('@/views/model/change_edit.vue')
          ),
          meta: { title: 'release.proedit', icon: 'chanpin', affix: true, activeMenu:"/plm/changes/list" },
        },
        {
          path: ':id(\\d+)/file',
          name: 'pnchanges_file_edit',
          hidden: true,
          component: Vue.defineAsyncComponent(
            () => import('@/views/model/change_file_edit.vue')
          ),
          meta: { title: 'release.proedit', icon: 'chanpin', affix: true, activeMenu:"/plm/changes/list" },
        },
        {
          path: 'pending',
          name: 'pnchanges_approve',
          hidden: true,
          component: Vue.defineAsyncComponent(
            () => import('@/views/model/change_approve.vue')
          ),
          meta: { title: 'release.proapprove', icon: 'chanpin', affix: true, activeMenu:"/plm/changes/list" },
        },
        {
          path: 'pending/:id(\\d+)/approve',
          name: 'pnchanges_approve_detail',
          hidden: true,
          component: Vue.defineAsyncComponent(
            () => import('@/views/model/change_approve_detail.vue')
          ),
          meta: { title: 'release.proapprove', icon: 'chanpin', affix: true, activeMenu:"/plm/changes/list" },
        },
        {
          path: 'editable',
          name: 'pnchanges_plan_edit',
          hidden: true,
          component: Vue.defineAsyncComponent(
            () => import('@/views/model/change_planedit.vue')
          ),
          meta: { title: 'release.waiting', icon: 'chanpin', affix: true, activeMenu:"/plm/changes/list"},
        },
        {
          path: 'editable/:id(\\d+)/edit',
          name: 'pnchanges_editable_edit',
          hidden: true,
          component: Vue.defineAsyncComponent(
            () => import('@/views/model/change_edit.vue')
          ),
          meta: { title: 'release.proedit', icon: 'chanpin', affix: true, activeMenu:"/plm/changes/list" },
        },
        {
          path: 'feedbacks',
          name: 'pnchanges_feedback',
          hidden: true,
          component: Vue.defineAsyncComponent(
            () => import('@/views/model/change_feedback.vue')
          ),
          meta: { title: 'release.proapprove', icon: 'chanpin', affix: true, activeMenu:"/plm/changes/list"},
        },
      ]
    },
    {
      path: 'software/changes',
      name: 'softchange',
      meta: {
        title: 'soft_change.pro_soft_change',
        icon: 'track-changes',
        affix: true,
      },
      children: [
        {
          path: '',
          name: 'softchanges',
          component: Vue.defineAsyncComponent(
            () => import('@/views/model/software_change_notice.vue')
          ),
          meta: {
            title: 'soft_change.pro_soft_change',
            icon: 'track-changes',
            affix: true,
          },
          activeMenu:"/plm/software/changes"
        },
        {
          path: 'add',
          name: 'softchange_add',
          hidden: true,
          component: Vue.defineAsyncComponent(
            () => import('@/views/model/soft_change_add.vue')
          ),
          meta: { title: 'release.add_new_software_change', icon: 'chanpin', affix: true, activeMenu:"/plm/software/changes" },
        },
        {
          path: ':id(\\d+)/edit',
          name: 'softchange_edit',
          hidden: true,
          component: Vue.defineAsyncComponent(
            () => import('@/views/model/soft_change_edit.vue')
          ),
          meta: { title: 'release.proadd', icon: 'chanpin', affix: true, activeMenu:"/plm/software/changes" },
        },
        {
          path: ':id(\\d+)/overedit',
          name: 'softchange_overedit',
          hidden: true,
          component: Vue.defineAsyncComponent(
            () => import('@/views/model/soft_change_overedit.vue')
          ),
          meta: { title: 'release.proadd', icon: 'chanpin', affix: true, activeMenu:"/plm/software/changes" },
        },
        {
          path: ':id(\\d+)',
          name: 'softchange_view',
          hidden: true,
          component: Vue.defineAsyncComponent(
            () => import('@/views/model/soft_change_view.vue')
          ),
          meta: { title: 'release.change_detail', icon: 'chanpin', affix: true, activeMenu:"/plm/software/changes" },
        },
        {
          path: 'pending',
          name: 'softchange_approve',
          hidden: true,
          component: Vue.defineAsyncComponent(
            () => import('@/views/model/softchange_approve.vue')
          ),
          meta: { title: 'release.proapprove', icon: 'chanpin', affix: true, activeMenu:"/plm/software/changes" },
        },
        {
          path: ':id(\\d+)/approve',
          name: 'softchange_approve_det',
          hidden: true,
          component: Vue.defineAsyncComponent(
            () => import('@/views/model/softchange_approve_det.vue')
          ),
          meta: { title: 'release.proapprove', icon: 'chanpin', affix: true, activeMenu:"/plm/software/changes" },
        },
      ]
    },
    {
      path: 'techinfo',
      name: 'tech',
      component: Vue.defineAsyncComponent(
        () => import('@/views/techInfo/product_folders.vue')
      ),
      meta: {
        title: 'menus.techinfo',
        icon: 'document',
        affix: true,
        showRoles: true,
        activeMenu:"/plm/techinfo" 
      },
      children: [
        {
          path: 'info_boards/:level(\\d+)/:owner(\\d+)/:name',
          name: 'tech_info_boards',
          hidden: true,
          component: Vue.defineAsyncComponent(
            () => import('@/views/techInfo/info_board.vue')
          ),
          meta: {
            title: 'menus.board',
            icon: 'tech',
            showRoles: false,
            showRole1: true,
          },
          children: [
            {
              path: 'files/:file_id(\\d+)/:filename',
              name: 'tech_files',
              hidden: true,
              component: Vue.defineAsyncComponent(
                () => import('@/views/techInfo/files.vue')
              ),
              meta: {
                title: 'menus.file',
                icon: 'tech',
                showRole1: false,
                showRole2: true,
              },
            },
          ],
        },
      ],
    },
    {
      path: 'techinfo2',
      name: 'tech2',
      component: Vue.defineAsyncComponent(
        () => import('@/views/techInfo/product_folders2.vue')
      ),
      meta: {
        title: 'menus.techinfo2',
        icon: 'document',
        affix: true,
        showRoles: true,
        activeMenu:"/plm/techinfo2" 
      },
      children: [
        {
          path: 'info_boards2/:level(\\d+)/:owner(\\d+)/:name',
          name: 'tech_info_boards2',
          hidden: true,
          component: Vue.defineAsyncComponent(
            () => import('@/views/techInfo/info_board2.vue')
          ),
          meta: {
            title: 'menus.board',
            icon: 'tech',
            showRoles: false,
            showRole1: true,
          },
          children: [
            {
              path: 'files2/:file_id(\\d+)/:filename',
              name: 'tech_files2',
              hidden: true,
              component: Vue.defineAsyncComponent(
                () => import('@/views/techInfo/files2.vue')
              ),
              meta: {
                title: 'menus.file',
                icon: 'tech',
                showRole1: false,
                showRole2: true,
              },
            },
          ],
        },
      ],
    },
  ],
}
