import * as Vue from 'vue'
import Layout from '@/layout'
export default {
  path: '/documents',
  component: Layout,
  name: 'documents', //文档资料
  alwaysShow: true,
  meta: { title: 'menus.documents', icon: 'books' },
  children: [
    {
      path: 'manual',
      name: 'material_manual',
      component: Vue.defineAsyncComponent(
        () => import('@/views/customer/manual.vue')
      ),
      meta: { title: 'menus.instruction', icon: 'tech' },
    },
  ],
}
